<div *ngIf="isLoaded"  class="card-container">

  <a class="header-back" [routerLink]="['/app']">
    <mat-icon inline=true fontSet="fa" fontIcon="fa-arrow-left" aria-label="back"></mat-icon>
    <span>My portfolios</span>
  </a> / {{portfolio.name}}
 
  <a id="opt" >
    <mat-icon fontSet="fa" fontIcon="fa-cogs"  aria-label="save">> </mat-icon>
  </a>

  <mat-toolbar>
    <h1>Risk analysis: {{optimName}}</h1>
  </mat-toolbar>

    <mat-card class="mb-3" id="risk-expected-risk" style="height: 300px !important;">
        <mat-card-header>Expected Risk/Return</mat-card-header>
        <mat-card-content>
            <div class="info-section">
                <div class="info-line">
                    <span class="info-title">Start date</span>
                    <span class="info-value">{{ portfolio.start_date }}</span>
                </div>
                <div class="info-line">
                    <span class="info-title">End date</span>
                    <span class="info-value">{{ portfolio.end_date }}</span>
                </div>
                <div class="info-line">
                    <span class="info-title">Number of Assets</span>
                    <span class="info-value">{{ riskContributionData ?  riskContributionData.length : "-"}}</span>
                </div>
                <div class="info-line">
                    <span class="info-title">Expected return</span>
                    <span class="info-value" *ngIf="resultsTableDataFooter[0]"
                          style="text-transform: capitalize">{{resultsTableDataFooter[0].expectedReturn  }}</span>
                </div>
                <div class="info-line">
                    <span class="info-title">Expected volatility</span>
                    <span class="info-value" *ngIf="resultsTableDataFooter[0]">{{ resultsTableDataFooter[0].volatility  }}</span>
                </div>
                <div class="info-line">
                    <span class="info-title">Expected sharpe</span>
                    <span class="info-value" *ngIf="resultsTableDataFooter[0]">{{ resultsTableDataFooter[0].sharpe}}</span>
                </div>
                <div class="info-line">
                    <span class="info-title">VAR 95</span>
                    <span class="info-value"
                          style="text-transform: capitalize">{{ var95 * 100|number:'1.2-2' }}</span>
                </div>
                <div class="info-line">
                    <span class="info-title">VAR 99</span>
                    <span class="info-value">{{ var99 * 100|number:'1.2-2' }}</span>
                </div>

            </div>
        </mat-card-content>
    </mat-card>



    <mat-card class="mb-3">
        <mat-card-content>
            <section id="risk-return-contribution">
                <div *ngIf="riskContributionData" >
                    <app-risk-contribution-column-chart
                            [riskContributionRaw]="riskContributionData"
                            [subHeader]="{portfolio: portfolio.name}">
                    </app-risk-contribution-column-chart>
                </div>
            </section>
        </mat-card-content>
    </mat-card>


    <mat-card class="mb-3">
          <mat-card-content>
            <section id="risk-covarianceMatrixTableData">
              <app-heatmap-table *ngIf="covarianceMatrixTableData" title="Covariance matrix" [dataRaw]="covarianceMatrixTableData" [allowedTickers]="allowedTickers"></app-heatmap-table>
            </section>
          </mat-card-content>
        </mat-card>

      <mat-card class="mb-3">
          <mat-card-content>
            <section id="risk-correlationMatrixTableData">
              <app-heatmap-table *ngIf="correlationMatrixTableData" title="Correlation matrix" [dataRaw]="correlationMatrixTableData" [allowedTickers]="allowedTickers"></app-heatmap-table>
            </section>
          </mat-card-content>
        </mat-card>


      <mat-card class="mb-3">
          <mat-card-content>
            <section id="hot-spots">
              <app-heatmap-chart title="Hot spots" *ngIf="weightPieData" [dataRaw]="weightPieData"></app-heatmap-chart>
            </section>
          </mat-card-content>
        </mat-card>

      <mat-card class="mb-3">
          <mat-card-content>
            <section id="best-hedge">
              <app-heatmap-table title="Best hedge" [dataRaw]="correlationMatrixData"></app-heatmap-table>
            </section>
          </mat-card-content>
        </mat-card>


      <mat-card class="mb-3">
          <mat-card-content>
            <section id="risk-drawdown">
             <app-line-chart title="Drawdown" [series]="comboData" [extraOptions]="comboExtraOptions"></app-line-chart>
            </section>
          </mat-card-content>
        </mat-card>

      <mat-card>
          <mat-card-content>
            <section id="risk-volatility">
             <app-line-chart title="Volatility" [series]="volatilityData.series" [extraOptions]="drawDownExtraOptions"></app-line-chart>
            </section>
          </mat-card-content>
        </mat-card>
  </div>
<!--
<div class="row mb-3">
  <div class="col-md-6">
    <div class="card">
      <div class="card-header">Annualized Volatility (63d rolling)</div>
      <div class="card-sub-header"><span>Portfolio:</span> {{portfolio.name}}</div>
      <div class="card-sub-header"><span>Type:</span> {{selectedEvaluationNiceName}}</div>
      <div class="card-body">
        <app-line-chart [enableDatawrapper]="true" [series]="volatilityData.series" [extraOptions]="drawDownExtraOptions"></app-line-chart>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card">
      <div class="card-header">Drawdown & Performance</div>
      <div class="card-sub-header"><span>Portfolio:</span> {{portfolio.name}}</div>
      <div class="card-sub-header"><span>Type:</span> {{selectedEvaluationNiceName}}</div>
      <div class="card-body">
        <app-line-chart *ngIf="comboData" [chartStart]="comboDates.start" [chartEnd]="comboDates.end" [enableDatawrapper]="true" [series]="comboData" [extraOptions]="comboExtraOptions" (afterSetExtremes)="onComboChartDateChange($event)"></app-line-chart>
      </div>
    </div>
  </div>
</div> -->
<!-- 

<div class="row mb-3">
  <div class="col-md-6">
    <app-weight-pie-chart *ngIf="pieDataList" [lists]="pieDataList" [subHeader]="{portfolio: portfolio.name, type: selectedEvaluationNiceName}"></app-weight-pie-chart>
  </div>

  <div class="col-md-6">
      <div class="card">
        <div class="row">
          <div class="col-md-6">
            <div class="card-header pb-0">Diversification Effect</div>
            <div class="card-sub-header"><span>Portfolio:</span> {{portfolio.name}}</div>
            <div class="card-sub-header"><span>Type:</span> {{selectedEvaluationNiceName}}</div>
          </div>
          <div class="col-md-6" *ngIf="!isPrint">
            <div class="float-right pt-3 pr-3">
              <mat-form-field appearance="outline" class="header-select ">
                <mat-label>Type</mat-label>
                <mat-select [(ngModel)]="selectedDiversification" (ngModelChange)="initDiversificationChart()">
                  <mat-option [value]="'volatilty'">Volatilty</mat-option>
                  <mat-option [value]="'return'">Return</mat-option>
                  <mat-option [value]="'sharpe'">Sharpe</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="card-body pt-0">
          <app-line-chart *ngIf="loaded" [series]="riskDiversificationData.series" [extraOptions]="chart3Options" [chartConstructor]="'chart'"></app-line-chart>
        </div>
      </div>
  </div>
</div>
<div class="mb-3">
  <app-evaluation-charts *ngIf="loaded" [riskContributionRaw]="riskContributionData" [correlationBetaRaw]="correlationBetaData" [subHeader]="{portfolio: portfolio.name, type: selectedEvaluationNiceName}"></app-evaluation-charts>
</div>

  <div class="card">
    <div class="card-header pb-0">Marginal Risk Contribution</div>
    <div class="card-sub-header"><span>Portfolio:</span> {{portfolio.name}}</div>
    <div class="card-sub-header"><span>Type:</span> {{selectedEvaluationNiceName}}</div>
    <div class="card-body pt-0">
      <mat-form-field appearance="outline" class="header-select" style="position: absolute; top: 16px; right: 30px;">
        <mat-label>Type</mat-label>
        <mat-select [(ngModel)]="riskContributionType" (ngModelChange)="initRiskContributionCategoryChart()">
          <mat-option [value]="'sector_fs'">Sector</mat-option>
          <mat-option [value]="'country_hq_name'">Country</mat-option>
        </mat-select>
      </mat-form-field>
      <app-line-chart *ngIf="loaded" [series]="riskElasticityData" [extraOptions]="riskElasticityOptions" [chartConstructor]="'chart'"></app-line-chart>
    </div>
  </div>
</div>

</div> -->