import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {HomeComponent} from './pages/home/home.component';
import {LoginGuardService} from './shared/services/login-guard.service';
import {ForgotPwdComponent} from './pages/forgot-pwd/forgot-pwd.component';
import {SettingsComponent} from './pages/settings/settings.component';
import {RegistrationComponent} from './pages/registration/registration.component';
import {UsersComponent} from './pages/users/users.component';
import {UserGuideComponent} from './pages/user-guide/user-guide.component';
import {AdminGuardService} from './shared/services/admin-guard.service';
import {PortfolioComponent} from './pages/portfolio/portfolio.component';
import {EvaluationComponent} from './pages/evaluation/evaluation.component';
import {OverviewComponent} from './pages/overview/overview.component';
import {PortfolioEditComponent} from './pages/portfolio/portfolio-edit/portfolio-edit.component';
import {OptimizationComponent} from './pages/optimization/optimization.component';
import {PortfolioViewComponent} from './pages/portfolio/portfolio-view/portfolio-view.component';
import {PortfolioStressComponent} from './pages/portfolio/portfolio-stress/portfolio-stress.component';
import {ImpliedViewComponent} from './pages/implied-view/implied-view.component';
import {WatchlistComponent} from './pages/watchlist/watchlist.component';
import {WatchlistCreateComponent} from './pages/watchlist/watchlist-create/watchlist-create.component';
import {WatchlistViewComponent} from './pages/watchlist/watchlist-view/watchlist-view.component';
import {PageSelectionComponent} from './pages/portfolio/portfolio-create/page-selection/page-selection.component';
import {PageGenerateComponent} from './pages/portfolio/portfolio-create/page-generate/page-generate.component';
import {PageUploadComponent} from './pages/portfolio/portfolio-create/page-upload/page-upload.component';
import {PrintOverviewComponent} from "./pages/print/print-overview/print-overview.component";

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegistrationComponent
  },
  {
    path: 'password-reset/:token',
    component: ForgotPwdComponent
  },
  {
    path: 'password-reset',
    component: ForgotPwdComponent
  },
  {
    path: 'print',
    canActivate: [LoginGuardService],
    children: [
      {
        path: 'overview',
        component: PrintOverviewComponent
      }
    ]
  },
  {
    path: 'app',
    component: HomeComponent,
    canActivate: [LoginGuardService],
    children: [
      {
        path: '',
        component: PortfolioComponent
      },
      {
        path: 'portfolio',
        component: PortfolioEditComponent
      },
      {
        path: 'watchlist',
        component: WatchlistComponent
      },
      {
        path: 'watchlist-create',
        component: WatchlistCreateComponent
      },
      {
        path: 'watchlist-create/:id',
        component: WatchlistCreateComponent
      },
      {
        path: 'watchlist-view/:id',
        component: WatchlistViewComponent
      },
      /*{
        path: 'portfolio-create',
        component: PortfolioCreateComponent
      },*/
      {
        path: 'portfolio-create/select',
        component: PageSelectionComponent
      },
      {
        path: 'portfolio-create/generate',
        component: PageGenerateComponent
      },
      {
        path: 'portfolio-create/upload',
        component: PageUploadComponent
      },
      {
        path: 'portfolio/:id',
        component: PortfolioEditComponent
      },
      {
        path: 'portfolio-view/:id',
        component: PortfolioViewComponent,
        children: [
          {
            path: '',
            component: OverviewComponent
          },
          {
            path: 'overview',
            component: OverviewComponent
          },
          {
            path: 'overview/print',
            component: PrintOverviewComponent
          },
          {
            path: 'factor-analysis',
            component: EvaluationComponent
          },
          {
            path: 'optimization',
            component: OptimizationComponent
          },
          {
            path: 'views',
            component: ImpliedViewComponent
          },
          {
            path: 'stress',
            component: PortfolioStressComponent
          }
        ]
      },
      {
        path: 'settings',
        component: SettingsComponent
      },
      {
        path: 'users',
        canActivate: [AdminGuardService],
        component: UsersComponent
      },
      {
        path: 'guide',
        component: UserGuideComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'app'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, anchorScrolling: 'enabled', scrollOffset: [0, 70],      scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
