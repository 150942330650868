<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-6">
                        <h1>{{title}}</h1>
                    </div>
                </div>
            </div>
            <div class="card-body">
            <div class="row justify-content-center">
                <div class="col-12">
                    <highcharts-chart *ngIf="loaded"
                                      [Highcharts]="Highcharts"
                                      [options]="chartOptions"
                                      [constructorType]="chartConstructor"
                                      style="width: 100%; height: auto; display: block;"
                    ></highcharts-chart>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class=row style="width:800px; margin-left: 120px;">
        <div   [ngClass]="{'col-lg-2': dataRaw.length > 16, 'col-lg-3': dataRaw.length > 9 && dataRaw.length <= 16, 'col-lg-4': dataRaw.length > 4 && dataRaw.length <=9 }" [ngStyle]="{'background-color':data.color}" *ngFor="let data of dataRaw" >
            <span matTooltip="{{data.name}}" class=badge [ngStyle]="{'background-color':data.color}">{{ data.ticker }}:<br /> {{ data.risk }} % </span>
      </div>        
</div>   -->