import {KeyValuePair} from "./key-value.model";

export class CompanyModel {

  public id: number;
  public company_name: string;
  public currency_id: number;
  public cusip: string;
  public entity_id: string;
  public exchange_country_id: number;
  public exchange_id: number;
  public exchange_name: string;
  public exchange_code_fs: string;
  public fs_listing_id: string;
  public fs_local_ticker: string;
  public fs_regional_id: string;
  public fs_security_id: string;
  public fs_ticker: string;
  public hq_country_id: number;
  public industry_id: number;
  public isin: string;
  public p_first_date: string;
  public region_id: number;
  public sector_id: number;
  public security_id: string;
  public security_name_full: string;
  public security_type: string;
  public security_type_equity: string;
  public sedol: string;
  public market_value_usd: any;
  public market_value_start: any;

  public weight: number;
  public weightManuallyAdjusted: boolean;
  public selected: boolean;

  public currency: any;
  public overview: any;
  public quant: any;
  public rbics: any;
  public fundamental_basic_cf: any;
  public fundamental_basic_der_af: any;
  public sector: KeyValuePair;
  public market: KeyValuePair;
  public region: KeyValuePair;
  public country: KeyValuePair;
  public industry: KeyValuePair;
  public exchange: KeyValuePair;
  public exchange_country: KeyValuePair;

  public etf_reference_table: any;
  public fund_reference_table: any;

  public currentEPS: KeyValuePair;
  public priceToBook: KeyValuePair;
  public momentum: KeyValuePair;
  public rsi: KeyValuePair;
  public womenOnBoard: KeyValuePair;
  public peOwnership: KeyValuePair;
  public fundF;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.company_name = data.company_name;
      this.cusip = data.cusip;
      this.entity_id = data.entity_id;
      this.exchange_country_id = data.exchange_country_id;
      this.exchange_id = data.exchange_id;
      this.exchange_name = data.exchange_name;
      this.exchange_code_fs = data.exchange_code_fs;
      this.fs_listing_id = data.fs_listing_id;
      this.fs_local_ticker = data.fs_local_ticker;
      this.fs_regional_id = data.fs_regional_id;
      this.fs_security_id = data.fs_security_id;
      this.fs_ticker = data.fs_ticker;
      this.hq_country_id = data.hq_country_id;
      this.industry_id = data.industry_id;
      this.isin = data.isin;
      this.p_first_date = data.p_first_date ?? data.FundInceptionDate;
      this.region_id = data.region_id;
      this.sector_id = data.sector_id;
      this.security_id = data.security_id;
      this.security_name_full = data.security_name_full;
      this.security_type = data.security_type;
      this.security_type_equity = data.security_type_equity;
      this.sedol = data.sedol;
      this.market_value_usd = +data.market_value_usd;
      this.market_value_start = +data.market_value_start;
      this.currency = data.currency;
      this.overview = data.overview;
      this.etf_reference_table = data.etf_reference_table ? data.etf_reference_table : {};
      this.fund_reference_table = data;
      this.quant = data.quant ? data.quant : {};
      this.currency_id = data.currency_id ? data.currency_id  : this.fund_reference_table.currency_id;
      this.currency = data.currency ? data.currency : {id : this.fund_reference_table.currency_id, code: this.fund_reference_table.BaseCurrency}

      // Merge esg into quant
      // if (data.esg_rating) {
      //   this.quant = {
      //     ...this.quant,
      //     ...data.esg_rating
      //   };
      // }
      // if (data.esg_rating_sfdr) {
      //   this.quant = {
      //     ...this.quant,
      //     ...data.esg_rating_sfdr
      //   };
      // }
      // Merge overview data into quant



        this.quant = {
          ...this.quant,
          ...this.fundF
        };
      if (data.overview) {
        this.quant = {
          ...this.quant,
          ...data.overview
        };
      }

      this.fundamental_basic_cf = data.fundamental_basic_cf ? data.fundamental_basic_cf : {};
      // Merge fundamental_basic_cf data into quant
      if (data.fundamental_basic_cf) {
        this.quant = {
          ...this.quant,
          ...data.fundamental_basic_cf
        };
      }

      this.fundamental_basic_der_af = data.fundamental_basic_der_af ? data.fundamental_basic_der_af : {};
      // Merge fundamental_basic_der_af data into quant
      if (data.fundamental_basic_der_af) {
        this.quant = {
          ...this.quant,
          ...data.fundamental_basic_der_af
        };
      }

      this.adjustQuantValues();

      this.rbics = data.rbics;
      this.sector = new KeyValuePair(data.sector);
      this.region = new KeyValuePair(data.region);
      this.country = new KeyValuePair(data.country);
      this.industry = new KeyValuePair(data.industry);
      this.exchange = new KeyValuePair(data.exchange);
      this.exchange_country = new KeyValuePair(data.exchange_country);

      this.market = new KeyValuePair(data.market);
      this.currentEPS = new KeyValuePair(data.currentEPS);
      this.priceToBook = new KeyValuePair(data.priceToBook);
      this.momentum = new KeyValuePair(data.momentum);
      this.rsi = new KeyValuePair(data.rsi);
      this.womenOnBoard = new KeyValuePair(data.womenOnBoard);
      this.peOwnership = new KeyValuePair(data.peOwnership);

      this.weight = +data.weight || 0;
      if (data.pivot) {
        this.weight = data.pivot.weight * 100;
      }
      this.weightManuallyAdjusted = false;
      this.selected = data.selected == true;

      // ETF company name shuld be security_name_full
      if (this.security_type_equity === 'FUND') {
        this.company_name = this.fund_reference_table.name;
        this.sector_id = 23;
       // this.country_id = 24;
        this.market_value_usd = this.etf_reference_table ? this.etf_reference_table.aum : 0;
      }
    }
  }

  /* some data needs to be divided or multiplied */
  private adjustQuantValues() {
    const divideByMillion = [
      'etf_equity_aum',
      'hedge_fund_equity_aum',
      'institutional_equity_aum',
      'institutional_market_value',
      'passive_market_value',
      '21d_average_dollars_traded',
      '21d_median_dollars_traded',
      '252d_average_dollars_traded',
      '252d_median_dollars_traded',
      '63d_average_dollars_traded',
      '63d_median_dollars_traded',
      'insider_market_value',
      'etf_market_value',
      'hedge_fund_market_value',
      'active_market_value'
    ];

    const multiplyWithHundred = [
      'active_concentration',
      'country_concentration',
      'etf_concentration',
      'hedge_fund_concentration',
      'institutional_concentration',
      'passive_concentration',
      'rd_to_sales',
      '21d_average_traded_value_ratio',
      '21d_median_traded_value_ratio',
      '252d_average_traded_value_ratio',
      '252d_median_traded_value_ratio',
      '63d_average_traded_value_ratio',
      '63d_median_traded_value_ratio'
    ];

    if (this.quant) {
      Object.entries(this.quant).map(([key, val]) => {
        if (divideByMillion.includes(key)) {
          if (this.quant[key]) {
            this.quant[key] = this.quant[key] / 1000000;
          }
        }
        if (multiplyWithHundred.includes(key)) {
          if (this.quant[key]) {
            this.quant[key] = this.quant[key] * 100;
          }
        }
      });
    }
  }

  public isStock() {
    return this.security_type_equity === 'SHARE';
  }

  public isETF() {
    return this.security_type_equity === 'ETF';
  }
  public isFund() {
    return this.security_type_equity === 'FUND';
  }
}
