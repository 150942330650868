import {Component, Injector} from '@angular/core';
import {GeneralPortfolioComponent} from "../general-portfolio/general-portfolio.component";
import * as moment from "moment";
import { withLatestFrom, map, takeUntil, delay } from 'rxjs/operators';
import {std} from "mathjs";
import {PortfolioModel} from "../../shared/models/portfolio.model";
import {ColumnType} from "../../components/cim-datatable/cim-datatable.component";
import {EvaluationComponent} from "../evaluation/evaluation.component";
import {LoaderService} from "../../components/loading-bar/loader.service";
import { deepCopy } from '@angular-devkit/core/src/utils/object';

import { forkJoin, of } from 'rxjs';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent extends GeneralPortfolioComponent{
  benchmarkCalculating = false;
  filter: any = {
    start: null,
    end: null
  }

  annualChartType = 'annualized_return';

  treeMapData: any[] = null;
  volatilityData: any = null;
  drawdownData: any[] = null;
  generalData: any[] = [];
  portfolioData: any[] = [];
  portfolioReturn: any = null;

  riskContributionData: any[] = [];
  benchmarkReturn: any = [];
  evaluationDataLoaded: any = false;
  marginalRiskContributionData: any[] = [{data:[]}];
  portfolioLoadedInService: PortfolioModel = null;


  sharpeChartData: any[] = [];
  annualChartOptions: any = {
    xAxis:{
      gridLineWidth: 0,
      minorGridLineWidth: 0
    },
    yAxis: {
      opposite: false,
      title: {
        text: 'Return',
        style: {
          textTransform: 'capitalize'
        }
      },
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      plotLines: [{
        value: 0,
        width: 1,
        color: '#aaa',
        zIndex: 10
      }],
      stackLabels: {
        enabled: true,
      }
    },
    plotOptions: {
      series: {
        lineWidth: 2,
        groupPadding: 0.05,
        pointPadding: 0,
        dataGrouping: {
          enabled: false
        },
        dataLabels: {
          valueDecimals: 0,
          format: '{point.y:,.0f}%'
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.y}%</b>',
        },
      },
      column: {

        dataLabels: {
          enabled: true,
          format: '{y:.0f}%'
        }
      }
    },
  };

portfolioConstituentsTableColumns: any[] = [
    {id: 'company_name', label: 'Company', type: ColumnType.CompanyInfo},
    {id: 'type', label: 'Asset Class', type: ColumnType.Simple},
    {id: 'Weight', label: 'Weight', type: ColumnType.Percentage},
    {id: 'CAGR', label: 'CAGR', type: ColumnType.Percentage},
//    {id: 'returnContribution', label: 'Return Contribution', type: ColumnType.Percentage},
    {id: 'Volatility', label: 'Volatility', type: ColumnType.Percentage},
    {id: 'riskContribution', label: 'Risk Contribution', type: ColumnType.Percentage},
    {id: 'maxDrawdown', label: 'Max Drawdown', type: ColumnType.Percentage},
 //   {id: 'bestYear', label: 'Best Year', type: ColumnType.Percentage},
 //   {id: 'worstYear', label: 'Worst Year', type: ColumnType.Percentage},
    {id: 'Sharpe', label: 'Sharpe', type: ColumnType.Number2Decimal},
    // {id: 'sortino', label: 'Sortino', type: ColumnType.Number2Decimal},
  ];
  portfolioConstituentsTableData: any[] = [];
  evaluationData: any = {}
  isLoaded: boolean = false;

  constructor(injector: Injector) {
    super(injector);
  }

  initPage() {
    this.service.portfolio$.subscribe(res => this.portfolioLoadedInService = res);

    if (this.portfolio && this.portfolio.id) {

      this.setupData();
      
    }
  }




  async setupData() {
    if (!this.treeMapData) {
          this.extractTreeMapData();
      };

      if(!this.portfolio.benchmark){
     //    this.selectedBenchmark = 'R1NQQw==';
       }else{
          this.service.selectBenchmark(this.portfolio.benchmark);
       //   this.selectedBenchmark = this.portfolio.benchmark;
       }
    //   await this.fetchPerformancePlot();

      const results = forkJoin({
         
         e : await this.service.getBenchmark(btoa(this.portfolio.benchmark), this.portfolio.id),
         c : await this.service.fetchPerformancePlot(this.portfolio.id),
         v : await this.service.fetchEvaluationData(this.portfolio.id, true),
        // a : of('').pipe(delay(1500)) 
      }).subscribe(

        val => {
          this.service.selectBenchmark(val.e);
          this.selectedBenchmark = val.e;
          
          this.evaluationData = val.v;
          this.plotData = this.convertToPercentages(val.c);
          this.calculateBenchmark(val.e);
          this.updatePageWithBenchmark();
          this.setGeneralData();
          this.isLoaded = true;
           this.afterPlotDataReceived();
        });
     


      // const sss = this.service.getBenchmark('TVNDSSBXT1JMRA==', this.portfolio.id)
      // const fff = this.service.fetchEvaluationData(this.portfolio.id)
      // const r = sss.pipe(
      //   withLatestFrom(fff),
      //   map(([e,v]) => {return {e,v};})).subscribe(

      //   val => {
      //     this.calculateBenchmark(val.e);
      //     this.evaluationData = val.v;
      //     this.updatePageWithBenchmark();
      //     this.setGeneralData();
      //     this.isLoaded = true;
      //   });sharpeChartData


  }

  setGeneralData() {
    this.portfolio.symbol_performance.map((e: any) => {
      if (e.Ticker !== 'Portfolio') {
        let riskContribution = this.evaluationData.risk_contribution.find(a => a.Ticker ==  e.Ticker && a.Portfolio == 'baseline_portfolio').risk_contribution;
        this.generalData.push({
          Ticker: e.Ticker,
          Weight: e.Weight,
          CAGR: e.CAGR,
          riskContribution: riskContribution,
///returnContribution: e.Weight * e.CAGR,
          company_name:  e.reference_table !== null ? e.reference_table.company_name : e.fund_reference_table.name,
          sector: e.reference_table !== null ?  e.reference_table.sector_fs: 'FUND' ,
          Sharpe: e.Sharpe,
          Volatility: e.Volatility,
          sortino: (this.treeMapData[0].data.find(a => a.name == e.Ticker).value -1.5) / e.Volatility,
          maxDrawdown: e["Max Drawdown"],
          type: e.reference_table !== null ?  e.reference_table.security_type_equity : 'FUND'
        });

        // this.riskContributionData.categories.push(e.Ticker);
        // this.riskContributionData.series[0].data.push(riskContribution*100);
        // this.riskContributionData.series[1].data.push(e.Weight*100);
        this.riskContributionData.push({
          Ticker: e.reference_table !== null ?  e.reference_table.company_name : e.fund_reference_table.name,
          riskContribution: riskContribution*100,
          Weight: e.Weight*100,
          Return: this.treeMapData[0].data.find(a => a.name == e.Ticker).value*e.Weight
        });

        this.marginalRiskContributionData[0].data.push({
          company: e.reference_table !== null ? e.reference_table.company_name : e.fund_reference_table.name,
          risk: parseFloat(((e.Weight * riskContribution)*100).toFixed(2)),
          return: parseFloat(((e.Weight * e.totalReturn)*100).toFixed(2))
        });
      } else {
        this.portfolioData.push({
          CAGR: e.CAGR,
          Volatility: e.Volatility,
        
          Sharpe: e.Sharpe,
          TrackingError: e.TrackingError,
          Beta: e.Beta,
          Alpha: e.Alpha,
          maxDrawdown: e["Max Drawdown"]
        })
      }
    });

  }

  checkSelectedBenchmark() {
    if (this.benchmark) {
      this.selectedBenchmark = this.portfolio.benchmark = this.benchmark;

      this.updatePageWithBenchmark();
    }
  }

  dayOfTheYear() {
    let date: Date = new Date();
    return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;

  }
  afterPlotDataReceived() {
    let portfolioStart = new Date(this.portfolio.start_date).valueOf();
    let copy = deepCopy(this.plotData.cumulative_returns.Portfolio.filter(function(e){return e[0] >= +portfolioStart }));
    var d = new Date();
    let lastmonth = Math.floor(d.setMonth(d.getMonth()-1).valueOf()/1000);
    let dateOfTheYear = moment.utc(d).startOf('year').unix()*1000;

    this.drawdownData = this.calculateDrawdown(this.plotData.cumulative_returns, portfolioStart);
    this.initAnnualSharpeChart()
    this.initVolatilityChart()
    this.portfolioReturn = {
      mnt: ((this.plotData.cumulative_returns.Portfolio.slice(-1)[0][1]-this.plotData.cumulative_returns.Portfolio.filter(function(e){return e[0] >= lastmonth})[0][1])
        /this.plotData.cumulative_returns.Portfolio.filter(function(e){return e[0] >= lastmonth})[0][1])*100,
      totalReturn: ((copy.slice(-1)[0][1] - copy[0][1])/copy[0][1])*100,


      avgAnn: (this.plotData.annualized_return.Portfolio.reduce( (p,c) => p + c[1], 0) / this.plotData.annualized_return.Portfolio.length), 
      

      ytd: ((this.plotData.cumulative_returns.Portfolio.slice(-1)[0][1]-
        this.plotData.cumulative_returns.Portfolio.filter(function(e){return e[0] >= dateOfTheYear})[0][1])
        /this.plotData.cumulative_returns.Portfolio.filter(function(e){return e[0] >= dateOfTheYear})[0][1])*100,


      cagrY1: this.plotData.daily_returns.Portfolio.slice(-365).reduce(function (sum, ret) {return sum + ret[1]}, 0),
      cagrY2:  (Math.pow((this.plotData.cumulative_returns.Portfolio.slice(-1)[0][1]/this.plotData.cumulative_returns.Portfolio.slice(-252*2)[0][1]),(1/2))-1)*100,
      cagrY3:  (Math.pow((this.plotData.cumulative_returns.Portfolio.slice(-1)[0][1]/this.plotData.cumulative_returns.Portfolio.slice(-252*3)[0][1]),(1/3))-1)*100,
      cagrY5: (Math.pow((this.plotData.cumulative_returns.Portfolio.slice(-1)[0][1]/this.plotData.cumulative_returns.Portfolio.slice(-252*5)[0][1]),(1/5))-1)*100,
    //  cagrY10: (Math.pow((1.98/1),(1/10))-1)*100
      cagrY10: (Math.pow((this.plotData.cumulative_returns.Portfolio.slice(-1)[0][1]/this.plotData.cumulative_returns.Portfolio.slice(-252*10)[0][1]),(1/10))-1)*100,

    }
  }

  
  initVolatilityChart() {
    this.volatilityData = [];
    if (this.plotData && this.plotData.rolling_std_deviation) {
      this.volatilityData = {
        title: '',
        series: [{
          name: "Portfolio",
          type: 'line',
          data: this.plotData.rolling_std_deviation['Portfolio']
        }]
      };
    }
  }

  initAnnualSharpeChart() {

    let portfolioStart = new Date(this.portfolio.start_date).valueOf();
    this.sharpeChartData = [];
    if (this.plotData[this.annualChartType]) {
      let copy = deepCopy(this.plotData[this.annualChartType]['Portfolio'].filter(function(e){return e[0] >= portfolioStart }));
     // this.plotData[this.annualChartType].forEach( (k,v) => {
      this.sharpeChartData.push({
        name: 'Portfolio',
        data: copy.map(d => {
          d[0] = moment.utc(d[0]).startOf('year').unix()*1000;
          if (this.annualChartType == 'annualized_return' || this.annualChartType == 'annualized_std_deviation') {
          //  d[1] *= 100
          }
          return d;
        }),
        type: 'column'
      })
  //  });
    }

    if (this.benchmark) {
       var benchmarkData: any[] = [];
       let value = 0;

      this.benchmark.perfAnn.map((d: any) => {
        const date = moment.utc(d.year+"-01-01").startOf('year').unix()*1000;
        if (date >= portfolioStart) {
        if (this.annualChartType == 'annualized_sharpe')  value =  d.sharpe;
        if (this.annualChartType == 'annualized_return') value = d.return;
        if (this.annualChartType == 'annualized_std_deviation') value = d.volatility;

          benchmarkData.push([date, value])
        }
      })
      this.sharpeChartData.push({
        name: this.benchmark.name ?? 'Benchmark',
        data: benchmarkData,
        type: 'column'
      })
    }
    if (this.annualChartType === 'annualized_return') {
      this.annualChartOptions.yAxis.title.text = 'Return';
      this.annualChartOptions.yAxis.labels = {format: '{text}%'};
      this.annualChartOptions.plotOptions.series.tooltip = '{series.name}: <b>{point.y}%</b>';
      this.annualChartOptions.plotOptions.column.dataLabels.format = '{y:,.0f}%';
    } else if (this.annualChartType === 'annualized_std_deviation') {
      this.annualChartOptions.yAxis.title.text = 'Volatility';
      this.annualChartOptions.yAxis.labels = {format: '{text}%'};
      this.annualChartOptions.plotOptions.series.tooltip = '{series.name}: <b>{point.y}%</b>';
      this.annualChartOptions.plotOptions.column.dataLabels.format = '{y:,.0f}%';
    } else {
      this.annualChartOptions.yAxis.title.text = 'Sharpe Ratio';
      this.annualChartOptions.yAxis.labels = {format: '{text}'};
      this.annualChartOptions.plotOptions.series.tooltip = '{series.name}: <b>{point.y}%</b>';
      this.annualChartOptions.plotOptions.column.dataLabels.format = '{y:,.2f}';
    }
  }

  setColor(value, min, max) {
    let color = '#286E28';
    let percent = 100;

    if (value < 0) {
      color = '#c60000';
      percent = (Math.abs(value) / Math.abs(min)) * 100;
    } else {
      percent = (Math.abs(value) / Math.abs(max)) * 100;
    }
    return this.util.shadeColor(color, 100 - percent);
  }

  setColors(list, min, max) {
    Object.entries(list).map(([key,data]:[string,any]) => {
      list[key].color = this.setColor(data.realValue, min, max);
    })
  }

  extractTreeMapData() {
    if (this.portfolio.treemap_plot && this.portfolio.treemap_plot.length) {
      let return_data = []; //hist_returns_annualized
      let std_data = []; //std_dev_annualized

      let minReturn = 99999;
      let maxReturn = -99999;
      let minVolatility = 99999;
      let maxVolatility = -99999;

      Object.entries(this.portfolio.treemap_plot).map(([key,data]:[string,any]) => {
        let company = this.portfolio.security_overviews[data.Ticker] || null;
        let companyName = company ? company.company_name : this.getFundName(data.Ticker);
        let type = company ? company.security_type_equity : '';
        if (data.historical_returns) {
          if (minReturn > +data.historical_returns) {
            minReturn = +data.historical_returns;
          }
          if (maxReturn < +data.historical_returns) {
            maxReturn = +data.historical_returns;
          }

          return_data[data.Ticker] = {
            name: data.Ticker,
            company: companyName,
            value: +(data.historical_returns * 100).toFixed(2),
            realValue: +(data.historical_returns * 100).toFixed(2),
            country: data.country_hq_name,
            industry: data.industry_fs,
            sector: data.sector_fs,
            type,
          }
        }
        if (data.volatility) {
          if (minVolatility > +data.volatility) {
            minVolatility = +data.volatility;
          }
          if (maxVolatility < +data.volatility) {
            maxVolatility = +data.volatility;
          }
          std_data[data.Ticker] = {
            name: data.Ticker,
            company: companyName,
            value: +(Math.abs(data.volatility) * 100).toFixed(2),
            realValue: +(data.volatility * 100).toFixed(2),
            country: data.country_hq_name,
            industry: data.industry_fs,
            sector: data.sector_fs,
            type,
          }
        }
      });

      this.setColors(return_data, minReturn * 100, maxReturn * 100);
      this.setColors(std_data, minVolatility * 100, maxVolatility * 100);

      this.treeMapData = [
        {
          name: "Annualized Return",
          data: Object.values(return_data)
        },
        {
          name: "Annualized Volatility",
          data: Object.values(std_data)
        }
      ];
    }

  }

  onDateChange(prop) {
    this.filter[prop] = this.filter[prop].format('YYYY-MM-DD');
  }


  updatePageWithBenchmark() {
    let portfolioStart = new Date(this.portfolio.start_date).valueOf();
    var d = new Date();
    let lastmonth = d.setMonth(d.getMonth()-1).valueOf();
    let dateOfTheYear = moment.utc(d).startOf('year').unix()*1000;
    
    if(this.benchmark){
      var copy = deepCopy(this.benchmark.cumulative_returns.filter(function(e){return e.date >= +portfolioStart }));
      this.benchmarkReturn = {
      
        

        mnt: ((this.benchmark.cumulative_returns.slice(-1)[0].value-
          this.benchmark.cumulative_returns.filter(function(e){return e.date >= lastmonth})[0].value)
         /this.benchmark.cumulative_returns.filter(function(e){return e.date >= lastmonth})[0].value)*100,
        totalReturn: ((copy.slice(-1)[0].value - copy[0].value)/copy[0].value)*100,
        avgAnn: (this.benchmark.annualized_return.reduce( (p,c) => p + c[1], 0) / this.benchmark.annualized_return.length), 
        ytd:  ((this.benchmark.cumulative_returns.slice(-1)[0].value-
          this.benchmark.cumulative_returns.filter(function(e){return e.date >= dateOfTheYear})[0].value)
          /this.benchmark.cumulative_returns.filter(function(e){return e.date >= dateOfTheYear})[0].value)*100,
        cagrY1: (Math.pow((this.benchmark.cumulative_returns.slice(-1)[0].value/this.benchmark.cumulative_returns.slice(-252)[0].value),(1/2))-1)*100,
        cagrY2:  (Math.pow((this.benchmark.cumulative_returns.slice(-1)[0].value/this.benchmark.cumulative_returns.slice(-252*2)[0].value),(1/2))-1)*100,
        cagrY3:  (Math.pow((this.benchmark.cumulative_returns.slice(-1)[0].value/this.benchmark.cumulative_returns.slice(-252*3)[0].value),(1/3))-1)*100,
        cagrY5: (Math.pow((this.benchmark.cumulative_returns.slice(-1)[0].value/this.benchmark.cumulative_returns.slice(-252*5)[0].value),(1/5))-1)*100,
      //  cagrY10: (Math.pow((1.98/1),(1/10))-1)*100
        cagrY10: (Math.pow((this.benchmark.cumulative_returns.slice(-1)[0].value/this.benchmark.cumulative_returns.slice(-252*10)[0].value),(1/10))-1)*100

      }
  }
    this.initAnnualSharpeChart();

    // portfolio benchmark
    if(this.selectedBenchmark && typeof this.selectedBenchmark.id == 'number'){
      this.benchmarkCalculating = false;
    }
  };

  reloadPortfolioCalculatedData() {
    this.service.viewItem(this.portfolio.id).subscribe((resp: any) => {
      if (resp.id) {
        this.portfolio.performance_summary = resp.performance_summary;
        this.portfolio.symbol_performance = resp.symbol_performance;
        
        this.benchmarkCalculating = false;
      }
    })
  }

  onBenchmarkSelect(benchmark) {
    if (benchmark && benchmark.id) {
      this.service.notificationService.open("Calculating benchmark related data. Results will be ready soon.")
      this.selectedBenchmark =  benchmark;
      this.portfolio.benchmark =benchmark.name;
      this.benchmarkCalculating = true;
      super.onBenchmarkSelect();
    } else {
   //   this.selectedBenchmark = null;
   //   this.benchmark = null;
      this.updatePageWithBenchmark();
    }
  }

  onWebsocketSuccess(msg) {
    this.reloadPortfolioCalculatedData()
  }

  onCalculateReturnsClick() {
    
    if (
      !this.portfolio.start_date ||
      !this.portfolio.end_date
    ) {
      return this.service.notificationService.open('Fill the start and end dates.')
    }
    this.service.saveItem(this.portfolio, false).subscribe((resp: any) => {
      if (resp.id) {
        this.loadingService.isLoading.next(true)
        // Wait for R script to answer
        
        this.service.notificationService.open("Portfolio saved. Page will reload automatically");
        this.openEdWebsocket(this.portfolio.id);



        // User came from portfolio overview page, redirect him back
        // if (this.portfolio && this.portfolio.id && this.portfolio.id == resp.id) {
        //   this.router.navigate(['/app/portfolio-view/'+resp.id])
        // } else {
        //   this.router.navigate(['/app'])
        // }
      }
    });

 //    this.service.calculateReturnData(this.portfolio.id).subscribe((resp: any) => {
 //      this.service.notificationService.open('Calculation started');
 // //     this.openWebsocket();
 //    })


    
  }
  onDrawdownDatechange(event) {
    // console.log("drawdown date changed")
    // this.drawdownData = this.calculateDrawdown(this.plotData.cumulative_returns, event.start, event.end);
  }

  
  openEdWebsocket(portfolioID) {
    this.service.openPortfolioWebsocket(portfolioID).subscribe(
      msg => {
        console.log('message received: ', msg)
        if (msg.success) {
          if (msg.task == 'portfolio') {
            this.service.portfolioFinished.next({id: portfolioID}) // reload portfolio
            // websocket answered success true, close socket
            this.service.notificationService.open("#"+portfolioID + " portfolio computation finished")

            window.location.reload()

          }
        } else {
          this.service.notificationService.open("There was an error during portfolio computation.")
        }
        this.service.closeWebsocket();
      }, // Called whenever there is a message from the server.
      err => this.handleWebsocketClosure(err, portfolioID), // Called if at any point WebSocket API signals some kind of error.
      () => console.log('complete') // Called when connection is closed (for whatever reason).
    );
  }

  handleWebsocketClosure(resp, portfolioID) {
    console.log("websocket error", resp)
    // Browser closed (or ngnix) after 60sec, reopen socket
    if (resp.code === 1006) {
      this.openEdWebsocket(portfolioID);
    }
  }


}
