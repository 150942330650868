import {Injectable, Injector} from '@angular/core';
import {ApiEndpoints} from '../../ApiEndpoints';
import {DataService} from '../../shared/services/data.service';
import {map, tap} from 'rxjs/operators';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {LoaderService} from "../../components/loading-bar/loader.service";
import {PortfolioModel} from "../../shared/models/portfolio.model";
import {WebSocketSubject} from "rxjs/internal-compatibility";
import {webSocket} from "rxjs/webSocket";
import {CompanyInfoDialogComponent} from "../../components/company-info-dialog/company-info-dialog.component";
import {TitleCasePipe} from "@angular/common";
import {CompanyModel} from "../../shared/models/company.model";
import {EtfInfoDialogComponent} from "../../components/etf-info-dialog/etf-info-dialog.component";
import {FundInfoDialogComponent} from "../../components/fund-info-dialog/fund-info-dialog.component";

export const portfolioTypes = [
  { id: 0, name: 'Undefined' },
  { id: 1, name: 'Discretionary' },
  { id: 2, name: 'Advisory' },
  { id: 3, name: 'Model' },
  { id: 4, name: 'Fund' }
];

@Injectable({
  providedIn: 'root'
})
export class PortfolioService extends DataService {

  cacheFactorKey = 'cim-factor-cache';

  portfolioFinished = new BehaviorSubject({});
  private portfolio = new BehaviorSubject(new PortfolioModel({}));
  portfolio$ = this.portfolio.asObservable();

  protected benchmark = new BehaviorSubject({});
  benchmark$ = this.benchmark.asObservable();

  protected optim = new BehaviorSubject('efficient_portfolio_max_sharpe_ratio');
  optim$ = this.optim.asObservable();

  portfolioReturnPlotData:any = null;
  portfolioOptimizationData: any = null;
  portfolioEvaluationData: any = null;

  websocketConnection$: WebSocketSubject<any>;

  benchmarks: any[] = [];
  portfolioLists: any = null;
  portfolioListsChange: Subject<any> = new Subject<any>();

  performancePlotPercentagesConverted = false;
  titlecasePipe: TitleCasePipe;
  typeList = [ 'ETF', 'SHARE' ];

  constructor(
    injector: Injector,
  ) {
    super(injector);
    this.titlecasePipe = injector.get(TitleCasePipe);

  }

  checkSelectedSorting(currentSorting, newSorting) {
    if (!currentSorting) {
      currentSorting = newSorting;
    } else if (currentSorting.includes(newSorting)) {
      if (currentSorting[0] === '-') {
        currentSorting = null;
      } else {
        currentSorting = `-${newSorting}`;
      }
    } else {
      currentSorting = newSorting;
    }
    return currentSorting;
  }

  changePfOptim(message: string) {
    this.optim.next(message)
  }
  convertEvaluationType(type) {
    if (type == 'efficient portfolio max mu') {
      type = 'max return';
    } else if (type == 'efficient portfolio given mu') {
      type = 'target return';
    } else if (type == 'efficient portfolio given sigma') {
      type = 'target risk';
    } else if (type == 'my port') {
      type = 'my portfolio';
    } else if (type == 'minimum variance portfolio') {
      type = 'minimum risk'
    } else if (type == 'efficient portfolio max sharpe ratio') {
      type = 'max sharpe'
    }
    return this.titlecasePipe.transform(type);
  }

  fixEvaluationTypeNames(list) {
    return list.map(data => {
      data.name = this.convertEvaluationType(data.name)
      return data;
    })
  }

  selectPortfolio(portfolio) {
    this.portfolio.next(portfolio);
  }

  clear() {
    this.portfolio.next(new PortfolioModel({}));
  }

  selectBenchmark(item) {
    this.benchmark.next(item);
  }

  getItems() {
    
    return this.getRequest(ApiEndpoints.portfolios)
  }

  getItem(id?) {
    let url = ApiEndpoints.portfolio;
    if (id) {
      url += '/' + id;
    }
    return this.getRequest(url);
  }

  viewItem(id) {
    this.portfolioReturnPlotData = null;
    this.portfolioOptimizationData = null;
    this.portfolioEvaluationData = null;
    this.performancePlotPercentagesConverted = false;
    return this.getRequest(ApiEndpoints.portfolioView.replace(':id', id));
  }

  forkItem(id) {
    return this.getRequest(ApiEndpoints.portfolioFork.replace(':id', id));
  }

  getShareTargets(id) {
    return this.getRequest(ApiEndpoints.portfolioShareTargets.replace(':id', id));
  }

  saveItem(data, clear=true) {
    if(clear){
      delete data.country;
      delete data.currency;
      delete data.securities;
    }
    

    if (data.id) {
      return this.putRequest(ApiEndpoints.portfolio, data);
    } else {
      return this.postRequest(ApiEndpoints.portfolio, data);
    }
  }

  deleteItem(data) {
    return this.deleteRequest(ApiEndpoints.portfolio, data.id);
  }

  bulkDelete(ids) {
    return this.postRequest(ApiEndpoints.portfolioBulkDelete, ids);
  }

  shareItem(id, users) {
    return this.postRequest(ApiEndpoints.portfolioShare.replace(':id', id), {user_ids: users});
  }

  unshareItem(id, users = []) {
    return this.postRequest(ApiEndpoints.portfolioUnshare.replace(':id', id), {user_ids: users});
  }

  leaveShareItem(id) {
    return this.postRequest(ApiEndpoints.portfolioLeaveShare.replace(':id', id), {});
  }

  fetchData(filter, extraFilter?) {


    let endpoint = ApiEndpoints.stockFilters;
    if (extraFilter && extraFilter.type) {
      if (extraFilter.type === 'etf') {
        endpoint = ApiEndpoints.etfFilters;
        delete extraFilter.type;
      }
      
    }

    if (extraFilter.type) {
      if (extraFilter.type === 'fund') {
        endpoint = ApiEndpoints.fundFilters;
        delete extraFilter.type;
      }
      
    }
    return this.getRequest(endpoint, filter, extraFilter);
  }

  async fetchPerformancePlot(portfolioID) {
    if (this.portfolioReturnPlotData) {
      return of(this.portfolioReturnPlotData)
    }
    return this.getRequest(`${ApiEndpoints.portfolio}/${portfolioID}/performance-plot`).pipe(
      tap((resp: any) => {this.portfolioReturnPlotData = resp;})
    );
  }

  openPortfolioWebsocket(portfolioID) {

    if (this.websocketConnection$) {
      return this.websocketConnection$
    } else {
      this.websocketConnection$ = webSocket(this.wsURL + portfolioID)
      return this.websocketConnection$;
    }
  }

  isPortfolioFinished() {
    return this.portfolioFinished.asObservable()
  }

  closeWebsocket() {
    if (this.websocketConnection$) {
      this.websocketConnection$.complete();
      this.websocketConnection$ = null;
    }
  }

  uploadCSVFile(data) {
    return this.postRequest(ApiEndpoints.portfolioCompanyUpload, data, {}, {}, true)
  }

  fetchOptimizationData(id, forceNew = false) {
    if (this.portfolioOptimizationData && !forceNew) {
      return of(this.portfolioOptimizationData)
    }
    return this.getRequest(`${ApiEndpoints.portfolio}/${id}/optimization`).pipe(
      tap((resp: any) => {this.portfolioOptimizationData = resp;})
    );
  }

  async fetchEvaluationData(id, forceNew = false) {
    if (this.portfolioEvaluationData && !forceNew) {
      return of(this.portfolioEvaluationData)
    }
    return this.getRequest(`${ApiEndpoints.portfolio}/${id}/evaluation`).pipe(
      tap((resp: any) => {this.portfolioEvaluationData = resp;})
    );
  }

  saveOptimizationData(id, data) {
    return this.postRequest(`${ApiEndpoints.portfolio}/${id}/optimization`, data)
  }

  saveRiskData(id, data) {
    return this.postRequest(`${ApiEndpoints.portfolio}/${id}/risk`, data)
  }

  calculateReturnData(id) {
    return this.getRequest(`${ApiEndpoints.portfolio}/${id}/update-historical-returns`)
  }

  saveCovmatParams(id, data) {
    return this.postRequest(`${ApiEndpoints.portfolio}/${id}/calculate/covariance-matrix`, data)
  }

  getCorrelationMatrix(id) {
    return this.getRequest(`${ApiEndpoints.portfolio}/${id}/optimization/correlation-matrix`)
  }

  getCovarianceMatrix(id) {
    return this.getRequest(`${ApiEndpoints.portfolio}/${id}/optimization/covariance-matrix`)
  }

  getCloseAdj(companyID) {
    return this.getRequest(ApiEndpoints.companyCloseAdj.replace(':id', companyID));
  }
  getFundCloseAdj(companyID) {
    return this.getRequest(ApiEndpoints.fundCloseAdj.replace(':id', companyID));
  }

  getIs(securityId) {
    return this.getRequest(ApiEndpoints.companyIs.replace(':security', securityId));
  }
  getFundIs(securityId) {
    return this.getRequest(ApiEndpoints.fundIs.replace(':security', securityId));
  }

  getMarketValuesAtStart(data) {
    return this.postRequest(ApiEndpoints.portfolioMarketCaps, data)
  }

  getEvaluationData(portfolioID, evaluationType) {
    return this.getRequest(`${ApiEndpoints.portfolio}/${portfolioID}/evaluation-performance/${evaluationType}`)
  }

  async getBenchmarks() {
    if (this.benchmarks && this.benchmarks.length) {
      return of(this.benchmarks)
    }
    return this.getRequest(ApiEndpoints.benchmarks).pipe(
      tap((resp: any[]) => {this.benchmarks = resp})
    )
  }

  getBenchmark(benchmark_id, portfolio_id) {
    if(typeof benchmark_id == 'number'){
      // portfolio selected, simple number id
      return this.getRequest(`${ApiEndpoints.portfolio}/${benchmark_id}/performance-plot`)
        .pipe(map((response: any) => {
          let converted = {};
          Object.entries(response).map(([key, value]) => {
            converted[key] = value['Portfolio']
              .map((d:any[]) => {
              return {value: d[1], date:d[0]}
            })
          })
          return converted
        }));
    } else {
      // benchmark selected with string id
      
      return this.getRequest(`${ApiEndpoints.benchmark}/${benchmark_id}/portfolio/${portfolio_id}`)
    }
  }

  public openCompanyInfoDialog(ticker, company?) {
    const openDialog = (company) => {
      this.dialog.open(CompanyInfoDialogComponent, {
        data: { company},
        maxWidth: '1200px',
        minWidth: '1100px',
        enterAnimationDuration: '10ms'
      });
    }
    if (company) {
      openDialog(company)
    } else {
      this.portfolio$.subscribe((portfolio) => {
        if (portfolio && portfolio.security_overviews && portfolio.security_overviews[ticker]) {
          const comp = new CompanyModel(portfolio.security_overviews[ticker]);
          if (comp.isETF()) {
            this.openETFInfoDialog(comp);
          } else if (comp.isFund()) {
              this.openFundInfoDialog(comp);
          } else {
            openDialog(comp);
          }
        } else if (portfolio && portfolio.funds) {
          const fund = portfolio.funds.find(f => f.FundShareClassId === ticker);
          if (fund) {
            let fundParsed = new CompanyModel(fund);
            this.openFundInfoDialog(fundParsed);
          } else {
            this.notificationService.open('Could not find company details');
          }
        } else {
          this.notificationService.open('Could not find company details');
        }
      }).unsubscribe();
    }
  }

  public openETFInfoDialog(company) {
    if (company) {
      this.dialog.open(EtfInfoDialogComponent, {
        data: {company},
        maxWidth: '1000px',
        minWidth: '900px',
        enterAnimationDuration: '10ms'
      });
    }
  }

  public openFundInfoDialog(company) {
    if (company) {
      this.dialog.open(FundInfoDialogComponent, {
        data: {company},
        maxWidth: '1000px',
        minWidth: '900px',
        enterAnimationDuration: '10ms'
      });
    }
  }

  public extractPortfolioData(list) {
    let result = {}

    Object.entries(list).map(([key, values]:[string, any]) => {
      if(!values.map) {
        location.reload();
        return null;
      }
      result[key] = values.map((data: any) => {

        let risk = 0;
        let ret = 0;
        let market_cap = 0;
        let esg = 0;

        if (data.performance_summary && data.performance_summary['Annualized Std Dev'] && data.performance_summary['Annualized Std Dev'].Portfolio) {
          risk = +(data.performance_summary['Annualized Std Dev'].Portfolio*100).toFixed(2);
        }

        if (data.performance_summary && data.performance_summary['Annualized Return'] && data.performance_summary['Annualized Return'].Portfolio) {
          ret = +(data.performance_summary['Annualized Return'].Portfolio*100).toFixed(2);
        }

        if (data.performance_summary && data.performance_summary['ESG Score'] && data.performance_summary['ESG Score'].Portfolio) {
          esg = +(data.performance_summary['ESG Score'].Portfolio).toFixed(2);
        }

        return {
          ...data,
          last_updated: data.last_updated_at ? data.last_updated_at.replace(' ', 'T') : null,
          risk: risk,
          return: ret,
          esg: esg,
          market_cap: market_cap
        }
      })
    })
    return result;
  }

  public hasETF(list) {
    let hasETF = false;
    list.map(s => {
      if (s.security_type_equity === 'ETF') {
        hasETF = true;
      }
    });
    return hasETF;
  }

  saveModifiedOptimWeights(id, data) {
    return this.postRequest(ApiEndpoints.portfolioModifiedOptimWeights.replace(':id', id), data);
  }
}
