<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-6">
                        <h1>{{title}}</h1>
                    </div>
                </div>
            </div>
            <div class="card-body">

<button *ngIf="enableDatawrapper" class="datawrapper-btn" mat-button color="primary" (click)="onDatawrapperClick()">Publish</button>

<app-chart-placeholder [loaded]="loaded"></app-chart-placeholder>

<highcharts-chart *ngIf="loaded"
        [Highcharts]="Highcharts"
        [options]="chartOptions"
        [constructorType]="chartConstructor"
        (chartInstance)="setChartInstance($event)"
        style="width: 100%; height: auto; display: block;"
></highcharts-chart>
            </div>
        </div>
    </div>
</div>