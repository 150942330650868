import {Component, Input, OnInit, OnChanges} from '@angular/core';
import * as Highcharts from "highcharts/highstock";
import {high_colors} from "../general-chart/general-chart.component";

@Component({
    selector: 'app-donut-chart',
    templateUrl: './donut-chart.component.html',
    styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements OnInit {
    @Input() data: any;
    @Input() type: string = null;
    @Input() title: string = null;
    @Input() subHeader: any = {};

    loaded = false;
    Highcharts: typeof Highcharts = Highcharts;
    chartConstructor = 'chart';
    chartOptions: Highcharts.Options = {};
    index: any = 1;
    text = 'Weight';

    ngOnInit(): void {
        this.initChart()
    }
    ngOnChanges(): void {
        this.initChart()
    }
    initChart() {
        let formattedData: any[];
        if (this.type === 'weight') {
            formattedData = this.data.map((d: any) => {
                return [d.company_name, parseFloat((d.Weight * 100).toFixed(2)), d.Ticker];
            });
        } else if (this.type === 'allocation') {
            formattedData = this.data.map((d: any) => {
                let allocation = d.allocation[0] * 100;
                if (allocation > 0 && d.companyName !== 'Portfolio') {
                    return [d.companyName, parseFloat(allocation.toFixed(2))];
                } else {
                    return false;
                }
            }).filter((v) => {
                return v !== false;
            });
        } else {
            formattedData = this.data.map((d: any) => {
                let allocation = d.allocation * 100;
                if (allocation > 0) {
                    return [d.company_name, d.return];
                } else {
                    return false;
                }
            }).filter((v) => {
                return v !== false;
            });
            this.text = 'Return';
        }

        formattedData.sort((a, b) => {
            if (a[1] > b[1]) {
                return -1;
            } else if (a[1] < b[1]) {
                return 1;
            }
            return 0;
        })

        this.chartOptions = {
            chart: {
                height: '500px',
                type: 'area'
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            title: {
                text: ''
            },
            tooltip: {
                valueSuffix: '%',
                shared: true
            },
            plotOptions: {
                bar: {
                    colorByPoint: true,
                    colors: [
                        '#424CCD'
                    ],
                    negativeColor: '#9f2112'
                }
            },
            legend: {
                enabled: false
            },
            colors: high_colors,
            xAxis: {
                categories: formattedData.map(item => item[0]),
                title: {
                    text: null
                },
                gridLineWidth: 0,
                minorGridLineWidth: 0
            },
            yAxis: {
                labels: {
                    format: '{text}%'
                },
                gridLineWidth: 0,
                minorGridLineWidth: 0,
                title: {
                    text: this.text,
                    style: {
                        textTransform: 'none'
                    }
                },

            },
            series: [
                {
                    name: this.text,
                    type: 'bar',
                    data: formattedData.map(item => item[1]),
                }
            ]
        };


        setTimeout(() => this.loaded = true, 10);
    }
}
